// Library Imports
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

//  Local Imports
import Router from './routes';
import { Toast } from './components/shared';

const App = () => {
  return (
    <BrowserRouter>
      <Router />
      <Toast />
    </BrowserRouter>
  );
}

export default App;
