// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../network/https';
import { endPoints } from '../../network/constant';

// Initial state for States
const initialState = {
  states: [],
  cities: [],
  stateLoading: false,
  cityLoading: false,
  error: null,
}

// States API Function
export const statesFunction = createAsyncThunk(
  'states/states',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.States, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// States API Function
export const citiesFunction = createAsyncThunk(
  'states/cities',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.States, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.error?.message);
    }
  }
);

// Reducers
export const statesSlice = createSlice({
  name: 'states',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(statesFunction.pending, (state) => {
        state.stateLoading = true;
        state.error = null;
        state.states = [];
      })
      .addCase(statesFunction.fulfilled, (state, action) => {
        state.stateLoading = false;
        state.states = action.payload;
        state.error = null;
      })
      .addCase(statesFunction.rejected, (state, action) => {
        state.stateLoading = false;
        state.error = action.payload;
        state.states = [];
      })
      .addCase(citiesFunction.pending, (state) => {
        state.cityLoading = true;
        state.error = null;
        state.cities = [];
      })
      .addCase(citiesFunction.fulfilled, (state, action) => {
        state.cityLoading = false;
        state.error = null;
        state.cities = action.payload;
      })
      .addCase(citiesFunction.rejected, (state, action) => {
        state.cityLoading = false;
        state.error = action.payload;
        state.cities = [];
      })
  },
});

export default statesSlice.reducer;
