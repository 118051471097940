// Library Imports
import React, { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom';

// Local Imports
import { publicRoutes, adminPrivateRoutes, userPrivateRoutes } from './config';
import PublicRoutes from './PublicRoutes';
import PrivateRoutes from './PrivateRoutes';
import ErrorPage from '../panel/ErrorPage';
import { useSelector } from 'react-redux';

const Router = () => {
    const user = useSelector((state) => state?.user?.user);
    const privateRoutes = user?.all_user_permissions?.includes('superadmin') ? adminPrivateRoutes : userPrivateRoutes;

    return (
        <Fragment>
            <Routes>
                {/* Private Routes */}
                <Route element={<PrivateRoutes />}>
                    {privateRoutes?.map((route, index) => (
                        <Route path={route.path} element={route.element} key={index} />
                    ))}
                </Route>

                {/* Public Routes */}
                <Route element={<PublicRoutes />}>
                    {publicRoutes?.map((route, index) => (
                        <Route path={route.path} element={route.element} key={index} />
                    ))}
                </Route>

                {/* Not Found Route */}
                <Route path="*" element={<ErrorPage />} />
            </Routes>
        </Fragment>
    );
};

export default Router;