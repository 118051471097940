import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local Imports
import { CustomModal, CustomPopover, CustomTable, SwitchButton, TextInput } from '../../../components/shared';
import { usersColumnData } from '../../../utils/mockupData';
import { DotsIcon, EyeIcon } from '../../../assets/icons';
import { usersListingFunction } from '../../../redux/reducers/adminReducers/usersReducer';

const Users = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state?.usersListing);
  const [users, setUsers] = useState([]);
  const [openPopover, setOpenPopover] = useState(false);
  const [openModal, setOpenModal] = useState({
    open: false,
    data: null
  });

  const getUsers = async () => {
    const response = await dispatch(usersListingFunction());
    const data = response?.payload?.map(data => {
      return {
        id: data?.id,
        name: data?.full_name,
        email: data?.email,
        phone: data?.phone_no,
        status: data?.is_active,
      }
    })
    setUsers(data)
  }

  useLayoutEffect(() => {
    getUsers()
  }, [])

  return (
    <div className='py-3 px-3'>
      <CustomTable
        rowsData={users?.length > 0 ? users : []}
        columns={usersColumnData}
        loading={loading}
        dataProviders={[
          {
            columnName: 'actions',
            component: ({ row }) => {
              return (
                <div aria-hidden="false" className='flex items-center gap-2'>
                  <CustomPopover
                    open={openPopover}
                    setOpen={setOpenPopover}
                    trigger={
                      <div>
                        <DotsIcon />
                      </div>
                    }
                  >
                    <div className='bg-white py-2 px-2 w-32 rounded-md'>
                      <div
                        className='flex items-center gap-2 cursor-pointer'
                        onClick={(e) => {
                          e.stopPropagation()
                          setOpenPopover(false)
                          setOpenModal({ open: true, data: row })
                        }
                        }
                      >
                        <EyeIcon width={18} height={18} />
                        <p>View</p>
                      </div>
                    </div>
                  </CustomPopover>
                </div>
              )
            }
          }
        ]}
      />

      <CustomModal
        title={'User Details'}
        open={openModal?.open}
        close={() => setOpenModal({ open: false, data: null })}
      >
        <TextInput
          label='Name'
          type='text'
          name='fname'
          disabled={true}
          value={openModal?.data?.name}
        />
        <TextInput
          label='Email'
          type='text'
          name='fname'
          disabled={true}
          value={openModal?.data?.email}
        />
        <TextInput
          label='Phone'
          type='text'
          name='fname'
          disabled={true}
          value={openModal?.data?.phone}
        />
        <SwitchButton
          label={"Status"}
          disabled={true}
          isSelected={openModal?.data?.status}
        />
      </CustomModal>
    </div>
  )
}

export default Users