import React from 'react';

export const IconNavigation = ({ icon, text, onClick, row, active }) => {
  return (
    <div
      onClick={onClick}
      className={`flex ${row ? 'items-end' : 'items-center'
        } cursor-pointer select-none w-full ${row ? 'flex-row justify-start gap-3' : 'flex-col justify-center'
        }`}
    >
      <div className={`${active ? 'text-primary' : 'text-grey'}`}>{icon}</div>
      <h6 className={`font-nunito text-[13px] ${active ? 'text-primary font-bold' : 'text-grey font-semibold'}`}>
        {text}
      </h6>
    </div>
  );
};
