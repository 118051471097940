// Library Imports
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

// Local Imports
import registerUserSlice from './reducers/authReducers/authReducers';
import rolesInterestsSlice from './reducers/authReducers/rolesInterestsReducer';
import statesSlice from './reducers/authReducers/statesReducer';
import usersListingSlice from './reducers/adminReducers/usersReducer';
import profileDetailsSlice from './reducers/userReducers/editProfileReducers/editProfileReducer';
import skillsSlice from './reducers/userReducers/skillsReducer/skillsReducer';


// Define configuration & reducers you want to persist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user'],
};

// Combine your reducers
const rootReducer = combineReducers({
  user: registerUserSlice,
  rolesInterests: rolesInterestsSlice,
  states: statesSlice,
  usersListing: usersListingSlice,
  profileDetails: profileDetailsSlice,
  skills: skillsSlice,
});

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store 
export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }),
})

// Create a persistor
export const persistor = persistStore(store);
