// Stagging Base URLS
export const baseURL = process.env.REACT_APP_API_ENDPOINT;
const apiVersion = 'v1';
const domain = baseURL + apiVersion;

// EndPoints
export const endPoints = {
  // Register User
  Register: `${domain}/registration/`,

  // Register User
  ForgetPassword: `${domain}/forget-password/`,

  // Register User
  ResetPassword: `${domain}/change-password/`,

  // Login User
  Login: `${domain}/login/`,

  // Roles & Inetrests
  RolesInterests: `${domain}/roles-interests/`,

  // States
  States: `${domain}/fetch-states/`,

  // Users
  Users: `${domain}/users/`,

  // User Detail
  GetProfileDetails: `${domain}/personal-info-detail/`,

  // Update User Detail
  UpdateProfileDetail: `${domain}/personal-info-update/`,

  // Skills
  getSkills: `${domain}/user-skill/`,
};