import React from 'react';
import { ScrollShadow } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

// Local Imports
import { assets } from '../../../assets/images';
import {
  AuthLayout,
  AvatarWithDetails,
  Button,
  PartnerCard,
  RedirectLink,
} from '../../../components/shared';

// Suggested Partners Data
const partners = [
  { id: 1, name: 'Gregory', designation: 'Lender', image: assets.lender1 },
  { id: 2, name: 'David', designation: 'Laywer', image: assets.lender2 },
  { id: 3, name: 'Hathaway', designation: 'Banker', image: assets.lender3 },
  { id: 4, name: 'Hathaway', designation: 'Banker', image: assets.lender4 },
  { id: 5, name: 'Hathaway', designation: 'Banker', image: assets.lender5 },
  { id: 6, name: 'Hathaway', designation: 'Banker', image: assets.lender6 },
  { id: 7, name: 'Hathaway', designation: 'Banker', image: assets.lender7 },
];

// Nearest Partner Data
const nearestPartners = [
  {
    id: 1,
    name: 'Gregory',
    designation: 'Lender',
    address: 'Lagos, Nigeria',
    image: assets.lender1,
  },
  {
    id: 2,
    name: 'David',
    designation: 'Laywer',
    address: 'Lagos, Nigeria',
    image: assets.lender2,
  },
  {
    id: 3,
    name: 'Hathaway',
    designation: 'Banker',
    address: 'Lagos, Nigeria',
    image: assets.lender3,
  },
  {
    id: 4,
    name: 'Hathaway',
    designation: 'Banker',
    address: 'Lagos, Nigeria',
    image: assets.lender4,
  },
  {
    id: 5,
    name: 'Hathaway',
    designation: 'Banker',
    address: 'Lagos, Nigeria',
    image: assets.lender5,
  },
  {
    id: 6,
    name: 'Hathaway',
    designation: 'Banker',
    address: 'Lagos, Nigeria',
    image: assets.lender6,
  },
];

const Suggestions = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <div className='w-full h-full flex items-center justify-center'>
        <div className='bg-white sm:rounded-lg w-full sm:w-[30rem] h-full sm:max-h-[700px] sm:min-h-[300px] overflow-auto px-5 py-5 my-10 shadow-lg'>
          <div className=''>
            <div className='font-nunito flex items-baseline justify-center mb-4'>
              <img src={assets.logo} alt='logo' className='size-12' />
              <p className='text-gold mt-3 text-2xl font-bold tracking-wide w-fit'>
                ealnest.
              </p>
            </div>

            {/* Login Headings */}
            <div className='font-nunito mb-5'>
              <p className='text-lg font-bold'>Suggested Partners</p>

              <div className='flex items-start gap-4 mt-5'>
                <AvatarWithDetails addPartner />

                <ScrollShadow
                  orientation='horizontal'
                  hideScrollBar
                  offset={8}
                  size={80}
                  className='flex items-start gap-4 h-[6rem] overflow-x-auto no-scrollbar'
                >
                  {partners?.map((item) => (
                    <AvatarWithDetails
                      key={item.id}
                      src={item?.image}
                      name={item?.name}
                      designation={item?.designation}
                    />
                  ))}
                </ScrollShadow>
              </div>
            </div>

            <div className='font-nunito mb-5 flex items-center justify-between'>
              <p className='text-xl font-bold'>Nearest</p>
              <RedirectLink text='See all' />
            </div>

            <ScrollShadow
              offset={10}
              size={50}
              className='flex flex-col gap-4 h-[calc(100vh-23rem)] sm:h-[20rem] overflow-auto no-scrollbar pb-6'
            >
              {nearestPartners?.map((item) => (
                <PartnerCard
                  key={item?.id}
                  src=''
                  name={item?.name}
                  designation={item?.designation}
                  address={item?.address}
                />
              ))}
            </ScrollShadow>

            <div>
              <Button
                variant={'filled'}
                color={'primary'}
                // disabled={}
                onClick={() => navigate('/success')}
              >
                Skip for now
              </Button>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Suggestions;
