import React from "react";
import { Select, SelectItem } from "@nextui-org/react";
import { useInfiniteScroll } from "@nextui-org/use-infinite-scroll";

// Local Imports
import { ArrowIcon } from "../../../assets/icons";

export const SelectDropdown = ({
  options,
  label = "Select an option",
  onChange,
  value,
  loading,
  multipleSelection,
  required = false,
  name,
  disabledKeys,
  disabled = false,
  error = false,
  errorMsg,
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [items, setItems] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const itemsPerPage = 20;
  const totalItems = options.length;

  // Check if there are more items to load
  const hasMore = page * itemsPerPage < totalItems;

  // Load more items when scrolled to the end
  const onLoadMore = () => {
    if (!hasMore) return;
    const nextPage = page + 1;
    const newItems = options.slice(0, nextPage * itemsPerPage);
    setItems(newItems);
    setPage(nextPage);
  };

  React.useEffect(() => {
    // Initialize with the first batch of items
    setItems(options.slice(0, itemsPerPage));
  }, [options]);

  const [, scrollerRef] = useInfiniteScroll({
    hasMore,
    isEnabled: isOpen,
    shouldUseLoader: false,
    onLoadMore,
  });

  return (
    <Select
      label={label}
      size="sm"
      scrollRef={scrollerRef}
      selectionMode={multipleSelection ? "multiple" : "single"}
      isRequired={required}
      isDisabled={disabled}
      disabledKeys={disabledKeys}
      scrollShadowProps={{
        isEnabled: false
      }}
      classNames={{
        trigger: 'bg-lightgrey shadow-none rounded-md pb-[2px]',
        label: 'scale-90 group-data-[focus=true]:-translate-y-2.5 group-data-[focus=true]:scale-75 group-data-[filled=true]:-translate-y-2.5 group-data-[filled=true]:scale-75 font-nunito text-grey',
        value: 'font-nunito text-[14px] placeholder:text-grey leading-4 pb-[4px]',
        popoverContent: 'rounded-md bg-white border border-grey/50',
        errorMessage: 'font-nunito text-[12px] text-danger',
      }}
      listboxProps={{
        itemClasses: {
          base: "rounded-md data-[hover=true]:bg-grey/30 data-[disabled=true]:text-grey/50 font-semibold !w-full",
        },
      }}
      selectedKeys={value}
      onSelectionChange={e => onChange({ target: { name, value: Array.from(e) } })}
      name={name}
      isInvalid={error}
      errorMessage={errorMsg}
      items={items}
      onOpenChange={setIsOpen}
    >
      {option => (
        <SelectItem key={option.id}>
          {option?.name}
        </SelectItem>
      )}
    </Select>
  );
}