import React from 'react';
import { Popover, PopoverTrigger, PopoverContent } from '@nextui-org/react';

export const CustomPopover = ({ open, setOpen, trigger, children }) => {
  return (
    <Popover
      placement='bottom'
      offset={10}
      classNames={{
        base: 'shadow-md !rounded-md',
        content: '!p-0 !rounded-md',
      }}
      // isOpen={open}
      // onOpenChange={() => setOpen(!open)}
      // onClose={() => {
      //   setOpen(false)
      // }}
    >
      <PopoverTrigger aria-expanded={false} className='cursor-pointer'>{trigger}</PopoverTrigger>
      <PopoverContent className='w-full'>{children}</PopoverContent>
    </Popover>
  );
};
