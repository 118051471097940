import { Input } from "@nextui-org/react";
import { useState } from "react";
import { CloseEyeIcon, EyeIcon } from "../../../assets/icons";

export const TextInput = ({
  value,
  onChange,
  disabled,
  label,
  type,
  name,
  required,
  maxLength,
  minLength,
  error,
  errorMsg,
  endContent,
  startContent,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  return (
    <Input
      label={label}
      labelPlacement="inside"
      type={type === "password" ? isVisible ? "text" : "password" : type}
      size="sm"
      name={name}
      isDisabled={disabled}
      value={value}
      onChange={disabled ? () => { } : onChange}
      isRequired={required}
      maxLength={maxLength}
      minLength={minLength}
      isInvalid={error}
      errorMessage={errorMsg}
      classNames={{
        inputWrapper: 'bg-lightgrey shadow-none rounded-md pb-[2px] group-data-[focus-visible=true]:ring-0',
        label: 'scale-90 group-data-[focus=true]:-translate-y-2.5 group-data-[focus=true]:scale-75 group-data-[filled=true]:-translate-y-2.5 group-data-[filled=true]:scale-75 font-nunito text-grey',
        input: 'font-nunito text-[14px] placeholder:text-grey leading-4 pb-[4px]',
        errorMessage: 'font-nunito text-[12px] text-danger',
      }}
      endContent={
        type === 'password' ? (
          <div
            className="my-auto cursor-pointer"
            onClick={toggleVisibility}>
            {isVisible ? (
              <CloseEyeIcon className="text-grey" />
            ) : (
                <EyeIcon className="text-grey" />
            )}
          </div>
        ) : (endContent)
      }
      startContent={startContent}
    />
  );
}