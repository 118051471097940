import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local Imports
import { Divider, TextInput, CustomModal, Spinner, showToast } from '../../../../components/shared';
import { EditIcon } from '../../../../assets/icons';
import { isError, validateEmail } from '../../../../utils/validations';
import { getProfileDetailFunction, updateProfileDetailFunction } from '../../../../redux/reducers/userReducers/editProfileReducers/editProfileReducer';
import { capitalizeFirstLetter } from '../../../../utils/utils';

// ProfileDetails Initial Data 
const initialProfileData = {
  firstName: '',
  lastName: '',
  location: '',
  address: '',
  physicalAddress: '',
  profession: '',
  gender: '',
  birthday: '',
  phoneNumber: '',
  email: '',
}

// ProfileDetails Initial Errors 
const initialProfileErrors = {
  firstName: '',
  lastName: '',
  location: '',
  address: '',
  physicalAddress: '',
  profession: '',
  gender: '',
  birthday: '',
  phoneNumber: '',
  email: '',
}

export const ProfileDetails = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.profileDetails);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [profileData, setProfileData] = useState(initialProfileData);
  const [formErrors, setFormErrors] = useState(initialProfileErrors);


  const handleCloseModal = () => {
    setOpenProfileModal(false);
    setFormErrors(initialProfileErrors);
  };

  const getProfileDetail = async () => {
    const data = await dispatch(getProfileDetailFunction());
    setProfileData(prev => ({
      ...prev,
      firstName: capitalizeFirstLetter(data?.payload?.first_name),
      lastName: capitalizeFirstLetter(data?.payload?.last_name),
      physicalAddress: data?.payload?.location[0]?.physical_address || '',
      profession: data?.payload?.profession || '',
      gender: data?.payload?.gender?.toLowerCase() || '',
      birthday: data?.payload?.birthday || '',
      phoneNumber: data?.payload?.phone_no || '',
      email: data?.payload?.email || '',
      address: data?.payload?.location[0]?.address || '',
    }));
  }

  useLayoutEffect(() => {
    getProfileDetail();
  }, []);

  const handleFormDataChange = (event) => {
    const { name, value } = event.target;
    setProfileData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormErrors((prev) => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleSave = async () => {
    if (!validateEmail(profileData?.email)) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: 'Invalid Email',
      }))
    }

    if (isError(formErrors)) {
      return;
    }

    const data = await dispatch(updateProfileDetailFunction({
      first_name: profileData?.firstName,
      last_name: profileData?.lastName,
      phone_no: profileData?.phoneNumber,
      profession: profileData?.profession,
      gender: profileData?.gender?.toUpperCase(),
      birthday: profileData?.birthday,
      location: {
        address: profileData?.address,
        physical_address: profileData?.physicalAddress,
      },
    }));

    if (data?.meta?.requestStatus === 'rejected') {
      showToast(
        `${'Something went wrong'}`,
        'error',
        { position: 'top-center' }
      );
      return false;
    }

    if (data?.meta?.requestStatus === 'fulfilled') {
      showToast('Profile Details Updated Successfully', 'success', {
        position: 'top-center',
      });
      handleCloseModal();
      getProfileDetail();
    }
  };

  return (
    <div>
      <div className='font-nunito mt-4 flex items-center justify-between gap-4 bg-white p-2 px-5 rounded-xl'>
        <h3 className='text-xl'>Profile</h3>
        <div
          className='cursor-pointer w-8 h-8 rounded-full hover:bg-grey/30 center transition-all duration-300'
          onClick={() => setOpenProfileModal(true)}
        >
          <EditIcon />
        </div>
      </div>
      {loading ? (
        <div className='h-[50vh] flex items-center justify-center'>
          <Spinner className={'size-10'} />
        </div>
      ) : (
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4'>
          <div className='bg-white rounded-xl p-3 font-nunito'>
            <p className='text-lg font-bold'>Personal Details</p>
            <div className='text-sm grid grid-cols-12 gap-3 mt-3'>
              <div className='flex flex-col gap-2 col-span-5'>
                <p>First Name:</p>
                <p>Last Name:</p>
                <p>Location:</p>
                <p>Address:</p>
                <p>Physical Address:</p>
                <p>Profession:</p>
                <p>Gender:</p>
                <p>Birthday:</p>
              </div>
              <div className='flex flex-col gap-2 col-span-7'>
                <p className='h-[20px]'>{profileData?.firstName}</p>
                <p className='h-[20px]'>{profileData?.lastName}</p>
                <p className='h-[20px]'>{profileData?.location}</p>
                <p className='h-[20px]'>{profileData?.address}</p>
                <p className='h-[20px]'>{profileData?.physicalAddress}</p>
                <p className='h-[20px]'>{profileData?.profession}</p>
                <p className='h-[20px]'>{profileData?.gender}</p>
                <p className='h-[20px]'>{profileData?.birthday}</p>
              </div>
            </div>
          </div>
          <div className='bg-white rounded-xl p-3 font-nunito'>
            <p className='text-lg font-bold'>Contact</p>
            <div className='text-sm grid grid-cols-12 mt-3'>
              <div className='flex flex-col gap-2 col-span-3'>
                <p>Phone:</p>
                <p>Email:</p>
              </div>
              <div className='flex flex-col gap-2 col-span-7'>
                <p>{profileData?.phoneNumber}</p>
                <p>{profileData?.email}</p>
              </div>
            </div>
          </div>
        </div>
      )}


      <CustomModal
        title={'Edit Profile'}
        open={openProfileModal}
        close={handleCloseModal}
        primaryButtonClick={handleSave}
        secondaryButtonClick={handleCloseModal}
        primaryButtonText={"Update"}
        primaryButtonLoading={loading}
      >
        <form onSubmit={handleSave} className='space-y-4'>
          <div className='flex flex-col gap-5'>
            <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
              <p className='font-bold text-[14px] text-primary'>Personal Details</p>
              <p className='font-bold text-[14px] text-darkgray'>*Required</p>
            </div>
            <h2 className='text-lg font-bold text-gray-800'>Personal Details</h2>
            <TextInput
              label='First Name'
              name='firstName'
              value={profileData.firstName}
              onChange={handleFormDataChange}
              error={formErrors.firstName}
              errorMsg={formErrors.firstName}
            />
            <TextInput
              label='Last Name'
              name='lastName'
              value={profileData.lastName}
              onChange={handleFormDataChange}
              error={formErrors.lastName}
              errorMsg={formErrors.lastName}
            />
            <TextInput
              label='Location'
              name='location'
              value={profileData.location}
              onChange={handleFormDataChange}
              error={formErrors.location}
              errorMsg={formErrors.location}
            />
            <TextInput
              label='Address'
              name='address'
              value={profileData.address}
              onChange={handleFormDataChange}
              error={formErrors.address}
              errorMsg={formErrors.address}
            />
            <TextInput
              label='Physical Address'
              name='physicalAddress'
              value={profileData.physicalAddress}
              onChange={handleFormDataChange}
              error={formErrors.physicalAddress}
              errorMsg={formErrors.physicalAddress}
            />
            <TextInput
              label='Profession'
              name='profession'
              value={profileData.profession}
              onChange={handleFormDataChange}
              error={formErrors.profession}
              errorMsg={formErrors.profession}
            />
            <div className='flex items-center space-x-4 '>
              <label className='font-semibold'>Gender:</label>
              <label>
                <input
                  type='radio'
                  name='gender'
                  value='male'
                  checked={profileData.gender === 'male'}
                  onChange={handleFormDataChange}
                />
                <span className='pl-1'>Male</span>
              </label>
              <label>
                <input
                  type='radio'
                  name='gender'
                  value='female'
                  checked={profileData.gender === 'female'}
                  onChange={handleFormDataChange}
                />
                <span className='pl-1'>Female</span>
              </label>
            </div>
            <TextInput
              label='Birthday'
              name='birthday'
              type='date'
              value={profileData.birthday}
              onChange={handleFormDataChange}
              error={formErrors.birthday}
              errorMsg={formErrors.birthday}
            />
          </div>

          <Divider />

          <div className='flex flex-col gap-5'>
            <h2 className='text-lg font-bold'>Contacts</h2>
            <TextInput
              label='Phone Number'
              name='phoneNumber'
              value={profileData.phoneNumber}
              onChange={handleFormDataChange}
              error={formErrors.phoneNumber}
              errorMsg={formErrors.phoneNumber}
            />
            <TextInput
              label='Email'
              name='email'
              value={profileData.email}
              onChange={handleFormDataChange}
              error={formErrors.email}
              errorMsg={formErrors.email}
            />
          </div>
        </form>
      </CustomModal>
    </div>
  );
};
