import React from 'react';

// Local Imports
import { PlusIcon } from '../../../assets/icons';

export const AddMore = ({ text, onClick, disabled }) => {
  return (
    <div className={`inline-flex items-center gap-3 w-fit ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} onClick={disabled ? null : onClick}>
      <div className={`size-10 rounded-full ${disabled ? 'bg-grey' : 'bg-primary'} color-white flex justify-center items-center`}>
        <PlusIcon color='white' />
      </div>
      <p className={`font-semibold text-[14px] ${disabled ? 'text-grey' : 'text-primary'}`}>{text}</p>
    </div>
  );
};
