// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../network/https';
import { endPoints } from '../../network/constant';

// Initial state for auth
const initialState = {
  user: null,
  loading: false,
  error: null,
}

// SignUp API Function
export const signupFunction = createAsyncThunk(
  'auth/register',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.Register, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// ForgetPassword API Fn
export const forgetPasswordFunction = createAsyncThunk(
  'auth/forgetPassword',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.ForgetPassword, {
        ...values,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// ResetPassword API Fn
export const resetPasswordFunction = createAsyncThunk(
  'auth/resetPassword',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.ResetPassword, {
        ...values,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Login API Function
export const loginFunction = createAsyncThunk(
  'auth/login',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(endPoints.Login, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Reducers
export const registerUserSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signupFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.user = null;
      })
      .addCase(signupFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(signupFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.user = null;
      })
      .addCase(loginFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.user = null;
      })
      .addCase(loginFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(loginFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.user = null;
      })
      .addCase(forgetPasswordFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.user = null;
      })
      .addCase(forgetPasswordFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      })
      .addCase(forgetPasswordFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.user = null;
      })
      .addCase(resetPasswordFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPasswordFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(resetPasswordFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = null;
      });
  },
});

export const { logout } = registerUserSlice.actions;
export default registerUserSlice.reducer;
