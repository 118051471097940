import React from 'react';
import { useNavigate } from 'react-router-dom';

// Local Imports
import { assets } from '../../../assets/images';
import { AuthLayout, Button } from '../../../components/shared';

const Success = () => {
  const navigate = useNavigate();
  return (
    <AuthLayout>
      <div className='w-full h-full flex items-center justify-center'>
        <div className='bg-white sm:rounded-lg w-full sm:w-[35rem] h-full sm:max-h-[600px] sm:min-h-[300px] overflow-auto px-5 py-5 my-10 shadow-lg'>
          <div className='font-nunito flex items-baseline justify-center my-[40px]'>
            <img
              src={assets.success}
              alt='logo'
              className='h-[180px] sm:h-[220px] '
            />
          </div>
          <h4 className='font-nunito font-bold text-[30px] text-center pt-[40px] '>
            Congratulations!
          </h4>
          <p className='font-nunito font-normal text-[16px] leading-[19px] text-center pt-[10px] pb-[40px] text-darkgrey'>
            Welcome to DealNset. We are happy to have as part of our growing
            network of RealEstate Developers
          </p>

          <div className='pb-[20px]'>
            <Button
              variant={'filled'}
              color={'primary'}
              // disabled={}
              onClick={() => navigate('/home')}
            >
              Set up Profile
            </Button>
          </div>
          <div className=''></div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Success;
