import React, { useState } from 'react';

// Local Imports
import { TextInput } from '../../../../components/shared';
import { CustomModal } from '../../../../components/shared';
import { ProfileCard } from '../../../../components/shared';
import { CertificationsIcon, EditIcon } from '../../../../assets/icons';
import { AddMore } from '../../../../components/shared/AddMore';

export const Certifications = () => {
  const [openCerificationsModal, setOpenCerificationsModal] = useState(false);
  const [certifications, setCertifications] = useState([
    { certificationName: '', organization: '', issueDate: '' },
  ]);

  const handleCertificationChange = (index, event) => {
    const { name, value } = event.target;
    setCertifications((prev) =>
      prev.map((cert, i) => (i === index ? { ...cert, [name]: value } : cert))
    );
  };

  const isCertificationComplete = (certification) => {
    return (
      certification.certificationName &&
      certification.organization &&
      certification.issueDate
    );
  };

  const addCertification = () => {
    if (certifications.every(isCertificationComplete)) {
      setCertifications((prev) => [
        ...prev,
        { certificationName: '', organization: '', issueDate: '' },
      ]);
    }
  };

  const handleSave = () => {
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpenCerificationsModal(false);
    setCertifications([
      { certificationName: '', organization: '', issueDate: '' },
    ]);
  };

  return (
    <div>
      <div className='font-nunito mt-4 flex items-center justify-between gap-4 bg-white p-2 px-5 rounded-xl'>
        <h3 className='text-xl'>Certifications</h3>
        <div
          className='cursor-pointer w-8 h-8 rounded-full hover:bg-grey/30 center transition-all duration-300'
          onClick={() => setOpenCerificationsModal(true)}
        >
          <EditIcon />
        </div>
      </div>
      <CustomModal
        title={'Add Certifications'}
        open={openCerificationsModal}
        close={handleCloseModal}
        // primaryButtonClick={handleSave}
        // secondaryButtonClick={handleCloseModal}
        // primaryButtonText={"Update"}
        // primaryButtonLoading={loading}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={handleSave} className='space-y-4'>
          <div className='flex flex-col gap-5'>
            <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
              <p className='font-bold text-[14px] text-primary'>Personal Certifications</p>
              <p className='font-bold text-[14px] text-darkgray'>*Optional</p>
            </div>
            <h2 className='text-lg font-bold text-gray-800'>Certifications</h2>
            {certifications.map((certification, index) => (
              <div key={index} className={`space-y-4 ${index > 0 && 'mt-2'}`}>
                <TextInput
                  label='Certification Name'
                  name='certificationName'
                  value={certification.certificationName}
                  onChange={(e) => handleCertificationChange(index, e)}
                />
                <TextInput
                  label='Issuing Organization'
                  name='organization'
                  value={certification.organization}
                  onChange={(e) => handleCertificationChange(index, e)}
                />
                <TextInput
                  label='Issue Date'
                  name='issueDate'
                  type='date'
                  value={certification.issueDate}
                  onChange={(e) => handleCertificationChange(index, e)}
                />
              </div>
            ))}
            <AddMore
              text={'Add More'}
              onClick={addCertification}
            />
          </div>
        </form>
      </CustomModal>
    </div>
  );
};
