import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Avatar } from '@nextui-org/react';
import { useSelector } from 'react-redux';

// Local Imports
import { assets } from '../../../assets/images'
import { adminSideMenuRoutes } from '../../../routes/config';
import { capitalizeFirstLetter, checkRoute, logoutFn } from '../../../utils/utils';
import { DotsIcon, DoubleArrowIcon } from '../../../assets/icons';
import { CustomPopover } from '../Popover';

const AdminLayout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sideMenu, setSideMenu] = useState(window?.innerWidth > 992 ? true : false);
  const user = useSelector((state) => state?.user)?.user;

  // Check & Get Active Route
  const currentPathName = adminSideMenuRoutes?.find(
    ({ text }) =>
      text?.toLowerCase() ===
      location?.pathname?.split('/')[1]?.split('-')?.join(' ')
  );

  return (
    <div className='flex w-full h-screen'>
      <div className={`bg-white border-r border-grey/30 ${sideMenu ? "w-[280px] z-10 absolute md:relative top-0 bottom-0 left-0" : "w-[70px] base:w-[80px] "} transition-all duration-500`}>
        {/* Side Menu Header */}
        <div
          className={`relative font-nunito flex items-baseline justify-center cursor-pointer p-2`}
          onClick={() => navigate('/users')}
        >
          <img src={assets.logo} alt='logo' className='size-7' />
          <p className={`text-gold text-md font-bold tracking-wide leading-3 w-fit ${sideMenu ? "block" : "hidden"}`}>
            ealnest.
          </p>
          <div
            className='z-40 absolute -right-[0.9rem] top-2 bg-white p-0.5 cursoe-pointer rounded-full shadow-md'
            onClick={() => setSideMenu(!sideMenu)}
          >
            <DoubleArrowIcon />
          </div>
        </div>

        {/* Side Menu Navigation */}
        <div className='px-2 mt-5 flex flex-col gap-2'>
          {adminSideMenuRoutes?.map((menu, index) => {
            return (
              <div
                key={index}
                className={`p-2 ${checkRoute(menu?.path) ? 'bg-secondary' : 'hover:bg-secondary/10'} cursor-pointer rounded-md`}
                onClick={() => navigate(menu?.path)}
              >
                <div className={`flex items-center ${sideMenu ? "justify-start" : "justify-center"} gap-2 ${checkRoute(menu?.path) ? 'text-white' : ''} `}>
                  {menu?.icon}
                  <p className={`text-sm font-nunito leading-4 ${sideMenu ? "" : "hidden"}`}>{menu?.text}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* Main */}
      <div className='relative w-full h-screen'>
        {/* Header */}
        <div className='bg-white py-4 pr-4 pl-8 border-b border-grey/30 text-xl font-nunito flex items-center justify-between'>
          <div>
            <p className='font-nunito'>{currentPathName?.text}</p>
          </div>

          {/* User Info */}
          <div className='self-end'>
            <CustomPopover
              trigger={
                <div>
                  <div className='hidden md:flex items-center gap-2'>
                    <Avatar
                      src='https://i.pravatar.cc/150?u=a042581f4e29026024d'
                      color='secondary'
                      showFallback
                    />
                  </div>
                  <div className='flex md:hidden items-center gap-2'>
                    <DotsIcon />
                  </div>
                </div>
              }
            >
              <div className='gap-3 w-48 flex flex-col items-center bg-white p-2 rounded-md'>
                <div className='flex flex-col gap-3 w-full border-b border-gray-300 pb-2'>
                  <div className='flex items-center gap-2'>
                    <Avatar
                      src='https://i.pravatar.cc/150?u=a042581f4e29026024d'
                      color='secondary'
                      showFallback
                    />
                    <div className='font-nunito'>
                      <p className='font-semibold text-[14px]'>{user ? capitalizeFirstLetter(user?.first_name) + ' ' + capitalizeFirstLetter(user?.last_name || '') : 'John Doe'}</p>
                      <p className='font-semibold text-[13px] text-darkgrey'>
                        {user ? user?.role : '--'}
                      </p>
                    </div>
                  </div>
                </div>

                <p
                  className='font-nunito font-semibold text-[14px] cursor-pointer'
                  onClick={() => {
                    logoutFn();
                    navigate('/login');
                  }}
                >
                  Log Out
                </p>
              </div>
            </CustomPopover>
          </div>
        </div>

        {/* Content */}
        {children}

        {/* Overlay */}
        {sideMenu ? (
          <div
            onClick={() => setSideMenu(false)}
            className='absolute top-0 left-0 right-0 h-full w-full bg-[rgba(0,0,0,0.3)] base:hidden'
          />
        ) : null}
      </div>
    </div>
  )
}

export default AdminLayout