import React from 'react';
import { Avatar } from '@nextui-org/react';

// Local Imports
import { ArrowIcon } from '../../../assets/icons';

export const PartnerCard = ({
  src,
  name,
  designation,
  address
}) => {
  return (
    <div className='border rounded-3xl bg-lightgrey p-3 flex items-center gap-3 group cursor-pointer select-none'>
      <Avatar
        showFallback
        size="xl"
        color='secondary'
        className="!size-16"
        classNames={{
          icon: "text-white",
        }}
        src={src}
      />
      <div className='font-nunito'>
        <p className='text-[14px] font-semibold'>{name}</p>
        <p className='text-[13px] text-darkgrey'>{designation}</p>
        <p className='text-[12px] text-grey'>{address}</p>
      </div>

      <div className='ml-auto mr-3 group-hover:translate-x-1 transition-all duration-300'>
        <ArrowIcon />
      </div>
    </div>
  )
};