import { getKeyValue, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from "@nextui-org/react";
import { Spinner } from "../Spinner";


export const CustomTable = ({
  columns,
  rowsData,
  dataProviders,
  loading
}) => {
  return (
    <Table
      aria-label="table"
      aria-hidden="false"
      isStriped={true}
      isHeaderSticky={true}
      fullWidth={true}
      classNames={{
        base: "max-h-[calc(100vh-100px)] overflow-auto",
        table: 'w-fit',
        wrapper: 'p-0',
        thead: 'bg-lightgrey text-[14px] font-nunito text-darkgrey overflow-hidden',
        th: 'font-bold',
        tr: 'even:bg-lightgrey py-1',
        td: 'py-3 font-nunito text-[14px] text-darkgrey'
      }}
    >
      <TableHeader columns={columns}>
        {(column) => {
          return (
            <TableColumn key={column?.name} width={'2000px'}>{column?.title}</TableColumn>
          )
        }}
      </TableHeader>

      <TableBody
        emptyContent={'No data found'}
        isLoading={loading}
        loadingContent={<Spinner className={"text-secondary size-10"} />}
      >
        {rowsData?.map((row) =>
          <TableRow key={row?.id}>
            {(columnKey) => {
              const dataProvider = dataProviders?.find(
                (provider) => provider.columnName === columnKey
              );
              if (dataProvider) {
                return <TableCell aria-hidden="false">{dataProvider.component({ row })}</TableCell>
              } else {
                return <TableCell aria-hidden="false">{getKeyValue(row, columnKey)}</TableCell>
              }
            }}
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};