import * as React from 'react';

export const ProfileIcon = ({ width, height, color, ...props }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width || 20}
    height={height || 20}
    viewBox='0 0 32 32'
    {...props}
  >
    <path
      fill={color || 'currentColor'}
      d='M12 4a5 5 0 1 1-5 5a5 5 0 0 1 5-5m0-2a7 7 0 1 0 7 7a7 7 0 0 0-7-7m10 28h-2v-5a5 5 0 0 0-5-5H9a5 5 0 0 0-5 5v5H2v-5a7 7 0 0 1 7-7h6a7 7 0 0 1 7 7zm0-26h10v2H22zm0 5h10v2H22zm0 5h7v2h-7z'
    />
  </svg>
);
