import React, { useState, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

// Local Imports
import { SelectDropdown, TextInput } from '../../../../components/shared';
import { citiesFunction } from '../../../../redux/reducers/authReducers/statesReducer';


const Locations = ({ formData, formErrors, setFormData, onChange, onSubmit, statesList }) => {
  const dispatch = useDispatch();
  const [currentCityList, setCurrentCityList] = useState([]);
  const [operatedCityList, setOperatedCityList] = useState([]);

  const handleOnChange = (event) => {
    const { value, name } = event.target

    setFormData(prev => ({
      ...prev,
      locations: {
        ...prev.locations,
        [name]: value
      }
    }))
  }

  // Get Cities Listing 
  const getCities = async (from) => {
    if (from === 'current') {
      const citiesList = await dispatch(citiesFunction({ id: formData?.currentState[0] }));
      setCurrentCityList(citiesList?.payload);
    }
    if (from === 'operated') {
      const citiesList = await dispatch(citiesFunction({ id: formData?.operatedState[0] }));
      setOperatedCityList(citiesList?.payload);
    }
  }

  useLayoutEffect(() => {
    if (!formData?.currentState) return;
    getCities('current');
  }, [formData?.currentState])

  useLayoutEffect(() => {
    if (!formData?.operatedState) return;
    getCities('operated');
  }, [formData?.operatedState])

  return (
    <>
      <div className='font-nunito mb-5 mt-3'>
        <p className='text-lg font-bold'>Where are you based?</p>
        <p className='text-darkgrey text-sm leading-4 mt-1'>I am currently living in</p>
      </div>

      {/* Form */}
      <form className='flex flex-col gap-5 mb-2' onSubmit={onSubmit}>
        <SelectDropdown
          options={statesList}
          loading={true}
          label='Select State'
          name='currentState'
          value={formData?.currentState}
          onChange={handleOnChange}
        />

        <SelectDropdown
          options={currentCityList}
          loading={true}
          label='Select City'
          name='currentCity'
          value={formData?.currentCity}
          onChange={handleOnChange}
        />

        <TextInput
          label='Zip Code'
          type='text'
          name='currentZipCode'
          // error={locationFormErrors?.name !== ''}
          // errorMsg={locationFormErrors?.name}
          value={formData?.currentZipCode}
          onChange={onChange}
        />

        <div className='font-nunito mb-5 mt-3'>
          <p className='text-lg font-bold'>Choose location to operate from</p>
          <p className='text-darkgrey text-sm leading-4 mt-1'>I would like to operate from</p>
        </div>

        <SelectDropdown
          options={statesList}
          loading={true}
          label='Select State'
          name='operatedState'
          value={formData?.operatedState}
          onChange={handleOnChange}
        />

        <SelectDropdown
          options={operatedCityList}
          label='Select City'
          name='operatedCity'
          value={formData?.operatedCity}
          onChange={handleOnChange}
        />

        <TextInput
          label='Zip Code'
          type='text'
          name='operatedZipCode'
          // error={locationFormErrors?.name !== ''}
          // errorMsg={locationFormErrors?.name}
          value={formData?.operatedZipCode}
          onChange={onChange}
        />
      </form>
    </>
  )
}

export default Locations