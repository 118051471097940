import React, { useState } from 'react';

// Local Imports
import { Divider, TextInput } from '../../../../components/shared';
import { CustomModal } from '../../../../components/shared';
import { ProfileCard } from '../../../../components/shared';
import { EditIcon, EducationIcon } from '../../../../assets/icons';
import { AddMore } from '../../../../components/shared/AddMore';

export const Education = ({ formErrors, onSubmit }) => {
  const [openEducationModal, setOpenEducationModal] = useState(false);
  const [educations, setEducations] = useState([
    { name: '', major: '', minor: '', startDate: '', endDate: '', honors: '' },
  ]);

  const handleEducationChange = (index, event) => {
    const { name, value } = event.target;
    setEducations((prevEducations) =>
      prevEducations.map((education, i) =>
        i === index ? { ...education, [name]: value } : education
      )
    );
  };

  const isEducationComplete = (education) => {
    return (
      education.name &&
      education.major &&
      education.minor &&
      education.startDate &&
      education.endDate &&
      education.honors
    );
  };

  const addEducation = () => {
    if (educations.every(isEducationComplete)) {
      setEducations((prevEducations) => [
        ...prevEducations,
        {
          name: '',
          major: '',
          minor: '',
          startDate: '',
          endDate: '',
          honors: '',
        },
      ]);
    }
  };

  const handleCloseModal = () => {
    setOpenEducationModal(false);
    setEducations([
      {
        name: '',
        major: '',
        minor: '',
        startDate: '',
        endDate: '',
        honors: '',
      },
    ]);
  };

  const handleSave = () => {
    handleCloseModal();
  };

  return (
    <div>
      <div className='font-nunito mt-4 flex items-center justify-between gap-4 bg-white p-2 px-5 rounded-xl'>
        <h3 className='text-xl'>Education</h3>
        <div
          className='cursor-pointer w-8 h-8 rounded-full hover:bg-grey/30 center transition-all duration-300'
          onClick={() => setOpenEducationModal(true)}
        >
          <EditIcon />
        </div>
      </div>
      <CustomModal
        title={'Education'}
        open={openEducationModal}
        close={handleCloseModal}
        // primaryButtonClick={handleSave}
        // secondaryButtonClick={handleCloseModal}
        // primaryButtonText={"Update"}
        // primaryButtonLoading={loading}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
          <p className='font-bold text-[14px] text-primary'>Academic History</p>
          <p className='font-bold text-[14px] text-darkgray'>*Optional</p>
        </div>

        <form className='flex flex-col gap-5 mb-2' onSubmit={onSubmit}>
          {educations.map((education, index) => (
            <div key={index} className={`space-y-4 ${index > 0 && 'mt-2'}`}>
              <TextInput
                label='Name of Education'
                type='text'
                name='name'
                error={formErrors?.name && formErrors?.name[index] !== ''}
                errorMsg={formErrors?.name && formErrors?.name[index]}
                value={education.name}
                onChange={(e) => handleEducationChange(index, e)}
              />
              <TextInput
                label='Major'
                type='text'
                name='major'
                error={formErrors?.major && formErrors?.major[index] !== ''}
                errorMsg={formErrors?.major && formErrors?.major[index]}
                value={education.major}
                onChange={(e) => handleEducationChange(index, e)}
              />
              <TextInput
                label='Minor'
                type='text'
                name='minor'
                error={formErrors?.minor && formErrors?.minor[index] !== ''}
                errorMsg={formErrors?.minor && formErrors?.minor[index]}
                value={education.minor}
                onChange={(e) => handleEducationChange(index, e)}
              />
              <div className='flex gap-4'>
                <TextInput
                  label='Start Date'
                  type='date'
                  name='startDate'
                  error={
                    formErrors?.startDate && formErrors?.startDate[index] !== ''
                  }
                  errorMsg={formErrors?.startDate && formErrors?.startDate[index]}
                  value={education.startDate}
                  onChange={(e) => handleEducationChange(index, e)}
                />
                <TextInput
                  label='End Date'
                  type='date'
                  name='endDate'
                  error={formErrors?.endDate && formErrors?.endDate[index] !== ''}
                  errorMsg={formErrors?.endDate && formErrors?.endDate[index]}
                  value={education.endDate}
                  onChange={(e) => handleEducationChange(index, e)}
                />
              </div>
              <TextInput
                label='Honors'
                type='text'
                name='honors'
                error={formErrors?.honors && formErrors?.honors[index] !== ''}
                errorMsg={formErrors?.honors && formErrors?.honors[index]}
                value={education.honors}
                onChange={(e) => handleEducationChange(index, e)}
              />
            </div>
          ))}

          <AddMore
            text='Add More'
            onClick={addEducation}
          />
        </form>
      </CustomModal>
    </div>
  );
};
