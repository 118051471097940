import React, { useState } from 'react';

// Local Imports
import { Divider, TextInput } from '../../../../components/shared';
import { CustomModal } from '../../../../components/shared';
import { ProfileCard } from '../../../../components/shared';
import { EditIcon, LicensesIcon } from '../../../../assets/icons';
import { AddMore } from '../../../../components/shared/AddMore';

export const Licenses = ({ formErrors, onSubmit }) => {
  const [openLicancesModal, setOpenLicancesModal] = useState(false);
  const [licenses, setLicenses] = useState([{ name: '' }]);

  const handleLicenseChange = (index, event) => {
    const { name, value } = event.target;
    setLicenses((prevLicenses) =>
      prevLicenses.map((license, i) =>
        i === index ? { ...license, [name]: value } : license
      )
    );
  };

  const isLicenseComplete = (license) => {
    return license.name;
  };

  const addLicense = () => {
    if (licenses.every(isLicenseComplete)) {
      setLicenses((prevLicenses) => [...prevLicenses, { name: '' }]);
    }
  };

  const handleCloseModal = () => {
    setOpenLicancesModal(false);
    setLicenses([{ name: '' }]);
  };

  const handleSave = () => {
    handleCloseModal();
  };

  return (
    <div>
      <div className='font-nunito mt-4 flex items-center justify-between gap-4 bg-white p-2 px-5 rounded-xl'>
        <h3 className='text-xl'>Licenses</h3>
        <div
          className='cursor-pointer w-8 h-8 rounded-full hover:bg-grey/30 center transition-all duration-300'
          onClick={() => setOpenLicancesModal(true)}
        >
          <EditIcon />
        </div>
      </div>
      <CustomModal
        title={'Licenses'}
        open={openLicancesModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
          <p className='font-bold text-[14px] text-primary'>Your Referrals</p>
          <p className='font-bold text-[14px] text-darkgray'>*Optional</p>
        </div>

        <form className='flex flex-col gap-5 mb-2' onSubmit={onSubmit}>
          {licenses.map((license, index) => (
            <div key={index} className={`space-y-4 ${index > 0 && 'mt-2'}`}>
              <TextInput
                label='Name of Licensure'
                type='text'
                name='name'
                error={formErrors?.name && formErrors?.name[index] !== ''}
                errorMsg={formErrors?.name && formErrors?.name[index]}
                value={license.name}
                onChange={(e) => handleLicenseChange(index, e)}
              />
            </div>
          ))}

          <AddMore
            text={'Add License'}
            onClick={addLicense}
          />
        </form>
      </CustomModal>
    </div>
  );
};
