import { Avatar } from "@nextui-org/react";
import { PlusIcon } from "../../../assets/icons";

export const AvatarWithDetails = ({
  addPartner = false,
  src,
  name,
  designation = 'Lawyer',
}) => {
  return (
    <div className="flex flex-col items-center justify-center w-fit font-nunito select-none cursor-pointer">
      {addPartner ? (
        <Avatar
          showFallback
          className="!size-12"
          color="secondary"
          fallback={
            <PlusIcon color={'white'} />
          }
        />
      ) : (
        <Avatar
          showFallback
          size="lg"
          color="secondary"
          className="!size-12"
          classNames={{
            icon: "text-white",
          }}
          src={src}
        />
      )}
      <p className="text-[12px] leading-4 text-center mt-2 capitalize">{addPartner ? "Add" : name}</p>
      <p className="text-[12px] text-grey leading-4 text-center capitalize">{addPartner ? "Partner" : designation}</p>
    </div>
  );
}