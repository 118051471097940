// Local Imports
import { DashboardLayout } from '../components/shared';
import ForgotPassword from '../panel/Auth/ForgotPassword';
import Login from '../panel/Auth/Login';
import ResetPassword from '../panel/Auth/ResetPassword';
import Signup from '../panel/Auth/Signup';
import Splash from '../panel/Auth/Splash';
import Suggestions from '../panel/Auth/Suggestions';
import Success from '../panel/Auth/Success';
import Home from '../panel/Dashboard/Home';
import Profile from '../panel/Dashboard/Profile';
import {
  ChatIcon,
  HomeIcon,
  PartnersIcon,
  ProfileIcon,
  ProjectsIcon,
} from '../assets/icons';
import AdminLayout from '../components/shared/AdminLayout';
import Users from '../panel/AdminPanel/Users';
import { Experience } from '../panel/Dashboard/Profile/Experience';
import { ProfileDetails } from '../panel/Dashboard/Profile/ProfileDetails';
import { Certifications } from '../panel/Dashboard/Profile/Certifications';
import { Education } from '../panel/Dashboard/Profile/Education';
import { Licenses } from '../panel/Dashboard/Profile/Licenses';
import { Recommendations } from '../panel/Dashboard/Profile/Recommendations';
import { Skills } from '../panel/Dashboard/Profile/Skills';



// USER SIDE MENU ROUTES
export const adminSideMenuRoutes = [
  {
    path: '/users',
    text: 'Users',
    icon: <ProfileIcon width={18} height={18} />,
  },
  {
    path: '/dashboard',
    text: 'Dashboard',
    icon: <ProfileIcon width={18} height={18} />,
  },
];

// USER SIDE MENU ROUTES
export const userSideMenuRoutes = [
  {
    path: '/home',
    text: 'Home',
    icon: <HomeIcon width={22} height={22} />,
  },
  {
    path: '/projects',
    text: 'Project',
    icon: <ProjectsIcon width={22} height={22} />,
  },
  {
    path: '/partners',
    text: 'Partners',
    icon: <PartnersIcon width={22} height={22} />,
  },
  {
    path: '/chat',
    text: 'Chat',
    icon: <ChatIcon width={20} height={20} />,
  },
];

// ADMIN PRIVATE ROUTES
export const adminPrivateRoutes = [
  {
    path: '/reset-password',
    moduleName: 'Authentication',
    element: <ResetPassword />,
  },
  {
    path: '/users',
    moduleName: 'Admin',
    element: <AdminLayout>
      <Users />
    </AdminLayout>,
  },
  {
    path: '/dashboard',
    moduleName: 'Admin',
    element: <AdminLayout>Dashboard</AdminLayout>,
  },
];

// USER PRIVATE ROUTES
export const userPrivateRoutes = [
  {
    path: '/reset-password',
    moduleName: 'Authentication',
    element: <ResetPassword />,
  },
  {
    path: '/suggestions',
    moduleName: 'Dashboard',
    element: <Suggestions />,
  },
  {
    path: '/success',
    moduleName: 'Dashboard',
    element: <Success />,
  },
  {
    path: '/home',
    moduleName: 'Dashboard',
    element: (
      <DashboardLayout>
        <Home />
      </DashboardLayout>
    ),
  },
  {
    path: '/projects',
    moduleName: 'Dashboard',
    element: (
      <DashboardLayout>
        Projects
      </DashboardLayout>
    ),
  },
  {
    path: '/partners',
    moduleName: 'Dashboard',
    element: (
      <DashboardLayout>
        Partners
      </DashboardLayout>
    ),
  },
  {
    path: '/chat',
    moduleName: 'Dashboard',
    element: (
      <DashboardLayout>
        Chat
      </DashboardLayout>
    ),
  },
  {
    path: '/profile',
    moduleName: 'Profile',
    element: (
      <DashboardLayout>
        <Profile />
      </DashboardLayout>
    ),
  },
  {
    path: '/experience',
    moduleName: 'Experience',
    element: (
      <DashboardLayout>
        <Experience />
      </DashboardLayout>
    ),
  },
  {
    path: '/certifications',
    moduleName: 'Certifications',
    element: (
      <DashboardLayout>
        <Certifications />
      </DashboardLayout>
    ),
  },
  {
    path: '/profile-details',
    moduleName: 'Profile Details',
    element: (
      <DashboardLayout>
        <ProfileDetails />
      </DashboardLayout>
    ),
  },
  {
    path: '/education',
    moduleName: 'Education',
    element: (
      <DashboardLayout>
        <Education />
      </DashboardLayout>
    ),
  },
  {
    path: '/licenses',
    moduleName: 'Licenses',
    element: (
      <DashboardLayout>
        <Licenses />
      </DashboardLayout>
    ),
  },
  {
    path: '/recommendations',
    moduleName: 'Recommendations',
    element: (
      <DashboardLayout>
        <Recommendations />
      </DashboardLayout>
    ),
  },
  {
    path: '/skills',
    moduleName: 'Skills',
    element: (
      <DashboardLayout>
        <Skills />
      </DashboardLayout>
    ),
  },
];

// PUBLIC ROUTES
export const publicRoutes = [
  {
    path: '/',
    moduleName: 'Authentication',
    element: <Splash />,
  },
  {
    path: '/login',
    moduleName: 'Authentication',
    element: <Login />,
  },
  {
    path: '/signup',
    moduleName: 'Authentication',
    element: <Signup />,
  },
  {
    path: '/forgot-password',
    moduleName: 'Authentication',
    element: <ForgotPassword />,
  },
];
