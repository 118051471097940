import React, { useState } from 'react';

// Local Imports
import { Divider, TextInput } from '../../../../components/shared';
import { CustomModal } from '../../../../components/shared';
import { ProfileCard } from '../../../../components/shared';
import { EditIcon, RecommendationIcon } from '../../../../assets/icons';
import { AddMore } from '../../../../components/shared/AddMore';

export const Recommendations = ({ formErrors, onSubmit }) => {
  const [openRecommendationModal, setOpenRecommendationModal] = useState(false);
  const [recommendations, setRecommendations] = useState([
    { name: '', relation: '', phoneNumber: '', email: '' },
  ]);

  const handleRecommendationChange = (index, event) => {
    const { name, value } = event.target;
    setRecommendations((prevRecommendations) =>
      prevRecommendations.map((recommendation, i) =>
        i === index ? { ...recommendation, [name]: value } : recommendation
      )
    );
  };

  const isRecommendationComplete = (recommendation) => {
    return (
      recommendation.name &&
      recommendation.relation &&
      recommendation.phoneNumber &&
      recommendation.email
    );
  };

  const addRecommendation = () => {
    if (recommendations.every(isRecommendationComplete)) {
      setRecommendations((prevRecommendations) => [
        ...prevRecommendations,
        { name: '', relation: '', phoneNumber: '', email: '' },
      ]);
    }
  };

  const handleCloseModal = () => {
    setOpenRecommendationModal(false);
    setRecommendations([
      { name: '', relation: '', phoneNumber: '', email: '' },
    ]);
  };

  const handleSave = () => {
    handleCloseModal();
  };

  return (
    <div>
      <div className='font-nunito mt-4 flex items-center justify-between gap-4 bg-white p-2 px-5 rounded-xl'>
        <h3 className='text-xl'>Recommendations</h3>
        <div
          className='cursor-pointer w-8 h-8 rounded-full hover:bg-grey/30 center transition-all duration-300'
          onClick={() => setOpenRecommendationModal(true)}
        >
          <EditIcon />
        </div>
      </div>
      <CustomModal
        title={'Recommendations'}
        open={openRecommendationModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
          <p className='font-bold text-[14px] text-primary'>Your Referrals</p>
          <p className='font-bold text-[14px] text-darkgray'>*Optional</p>
        </div>
        <form className='flex flex-col gap-5 mb-2' onSubmit={onSubmit}>
          {recommendations.map((recommendation, index) => (
            <div key={index} className={`space-y-4 ${index > 0 && 'mt-2'}`}>
              <TextInput
                label='Name of Reference'
                type='text'
                name='name'
                error={formErrors?.name && formErrors?.name[index] !== ''}
                errorMsg={formErrors?.name && formErrors?.name[index]}
                value={recommendation.name}
                onChange={(e) => handleRecommendationChange(index, e)}
              />
              <TextInput
                label='Relation'
                type='text'
                name='relation'
                error={formErrors?.relation && formErrors?.relation[index] !== ''}
                errorMsg={formErrors?.relation && formErrors?.relation[index]}
                value={recommendation.relation}
                onChange={(e) => handleRecommendationChange(index, e)}
              />
              <TextInput
                label='Phone Number'
                type='text'
                name='phoneNumber'
                error={
                  formErrors?.phoneNumber && formErrors?.phoneNumber[index] !== ''
                }
                errorMsg={
                  formErrors?.phoneNumber && formErrors?.phoneNumber[index]
                }
                value={recommendation.phoneNumber}
                onChange={(e) => handleRecommendationChange(index, e)}
              />
              <TextInput
                label='Email'
                type='email'
                name='email'
                error={formErrors?.email && formErrors?.email[index] !== ''}
                errorMsg={formErrors?.email && formErrors?.email[index]}
                value={recommendation.email}
                onChange={(e) => handleRecommendationChange(index, e)}
              />
            </div>
          ))}

          <AddMore
            onClick={addRecommendation}
            text='Add Recommendation'
          />
        </form>
      </CustomModal>
    </div>
  );
};
