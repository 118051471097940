import React from 'react'

// Local Imports
import { LongArrow } from '../../../assets/icons'

export const RedirectLink = ({text}) => {
  return (
    <div className='flex items-center gap-2 text-sm text-gold group cursor-pointer'>
      <p className='font-nunito font-bold'>{text}</p>
      <LongArrow className='group-hover:!translate-x-1 transition-all duration-300' />
    </div>
  )
}

export default RedirectLink