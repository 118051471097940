// Library Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Local Imports
import axios from '../../network/https';
import { endPoints } from '../../network/constant';

// Initial state for Roles & Interests
const initialState = {
  usersListing: [],
  loading: false,
  error: null,
}

// Roles & Interests API Function
export const usersListingFunction = createAsyncThunk(
  'admin/usersListing',
  async (values, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(endPoints.Users, {
        ...values,
      });
      return data?.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message);
    }
  }
);

// Reducers
export const usersListingSlice = createSlice({
  name: 'admin/usersListing',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(usersListingFunction.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.usersListing = [];
      })
      .addCase(usersListingFunction.fulfilled, (state, action) => {
        state.loading = false;
        state.usersListing = action.payload;
        state.error = null;
      })
      .addCase(usersListingFunction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.usersListing = [];
      })
  },
});

export default usersListingSlice.reducer;
