import React from 'react'
import { CrossIcon } from '../../../assets/icons'
import { capitalizeFirstLetter } from '../../../utils/utils';

export const Chip = ({ text, onClick }) => {
  const capitalizedText = text?.split(' ')?.map((item) => capitalizeFirstLetter(item))?.join(' ');
  return (
    <span
      className="inline-flex items-center gap-2 justify-center rounded-full px-3 py-2 bg-secondary"
    >
      <p className="whitespace-nowrap text-sm text-white font-nunito font-semibold">{capitalizedText}</p>

      <button
        className="rounded-full p-0.5 transition text-white cursor-pointer"
        onClick={onClick}
      >
        <CrossIcon />
      </button>
    </span>
  )
};