
// Email Validation
export const validateEmail = (email) => {
  var emailRegex = /^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
  return emailRegex.test(email);
};


// Password Validation
export const validatePassword = (password) => {
  var passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_\-+=<>?{}[\]~`|\\:;"',./]).{8,}$/;
  return passwordRegex.test(password);
};

// Empty Check
export const isEmpty = (obj) => {
  return Object.values(obj)?.some(value =>
    value === null ||
    value === undefined ||
    value === "" ||
    Number.isNaN(value)
  );
}

// Error Check
export const isError = (obj) => {
  return Object.values(obj)?.some(value =>
    value !== null &&
    value !== undefined &&
    value !== ""
  );
}