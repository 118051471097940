export const usersColumnData = [
  { name: 'name', title: 'Name', sorting: false },
  { name: 'email', title: 'Email', sorting: false },
  { name: 'phone', title: 'Phone', sorting: false },
  { name: 'status', title: 'Status', sorting: false },
  { name: 'actions', title: 'Actions' },
];

// export const userTableColumnExtensions = [
//   { columnName: 'name', width: 170 },
//   { columnName: 'email', width: 100 },
//   { columnName: 'phone', width: 120 },
//   { columnName: 'status', width: 120 },
//   { columnName: 'action', width: 50 },
// ];

export const users = Array.from({ length: 20 }, (_, index) => ({
  id: index + 1,
  name: `User ${index + 1}`,
  email: `user${index + 1}@example.com`,
  phone: `+1 234 5678${index + 1}9`,
  status: index % 2 === 0 ? 'Active' : 'Inactive',
}));