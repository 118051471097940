import React, { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local Imports
import { Chip, showToast, Spinner, TextInput } from '../../../../components/shared';
import { CustomModal } from '../../../../components/shared';
import { EditIcon } from '../../../../assets/icons';
import { AddMore } from '../../../../components/shared/AddMore';
import { deleteSkillsFunction, getSkillsFunction, updateSkillsFunction } from '../../../../redux/reducers/userReducers/skillsReducer/skillsReducer';
import { capitalizeFirstLetter } from '../../../../utils/utils';

export const Skills = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state?.skills);
  const [openSkillsModal, setOpenSkillsModal] = useState(false);
  const [skill, setSkill] = useState('');
  const [skillsList, setSkillsList] = useState([]);

  const handleCloseModal = () => {
    setOpenSkillsModal(false);
    setSkill('');
  };

  const getSkills = async () => {
    const data = await dispatch(getSkillsFunction());
    setSkillsList(data?.payload);
  }

  const deleteSkills = async (id) => {
    const data = await dispatch(deleteSkillsFunction({
      id: id
    }));
    getSkills();
  }

  useLayoutEffect(() => {
    getSkills();
  }, []);


  const addSkill = async () => {
    const data = await dispatch(updateSkillsFunction({
      name: skill
    }));

    if (data?.meta?.requestStatus === 'fulfilled') {
      showToast('Skill Added Successfully', 'success', {
        position: 'top-center',
      });
      setSkill('');
      getSkills();
    }
  };

  return (
    <div>
      <div className='font-nunito mt-4 flex items-center justify-between gap-4 bg-white p-2 px-5 rounded-xl'>
        <h3 className='text-xl'>Skills</h3>
        <div
          className='cursor-pointer w-8 h-8 rounded-full hover:bg-grey/30 center transition-all duration-300'
          onClick={() => setOpenSkillsModal(true)}
        >
          <EditIcon />
        </div>
      </div>
      <div className='font-nunito mt-4 bg-white rounded-lg p-3'>
        <div className='vertical-center gap-3'>
          <p className='font-semibold text-[14px]'>Skills</p>
          {loading ? <Spinner /> : null}
        </div>
        <div className='mt-6 flex items-center gap-3 flex-wrap'>
          {skillsList?.map((item, index) => (
            <Chip
              key={index}
              text={capitalizeFirstLetter(item?.name) || 'dummy'}
              onClick={() => deleteSkills(item?.id)}
            />
          ))}
        </div>
      </div>

      <CustomModal
        title={'Skills'}
        open={openSkillsModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
          <p className='font-bold text-[14px] text-primary'>Extra Skills</p>
          <p className='font-bold text-[14px] text-darkgray'>*Optional</p>
        </div>

        <div className='vertical-center gap-3'>
          <p className='font-nunito text-lg font-bold'>Professional Abilities</p>
          {loading ? <Spinner /> : null}
        </div>
        <form className='flex flex-col gap-5 mb-2' onSubmit={(e) => {
          e.preventDefault();
          addSkill();
        }}>
          <TextInput
            label='Skill'
            type='text'
            name='skill'
            value={skill}
            onChange={(e) => setSkill(e.target.value)}
          />

          <AddMore
            text={"Add Skill"}
            onClick={addSkill}
            disabled={loading || !skill}
          />
        </form>
      </CustomModal>
    </div>
  );
};
