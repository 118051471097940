import * as React from 'react';
export const RecommendationIcon = ({ width, height, color, ...props }) => (
  <svg
    width={width || '12'}
    height={height || '13'}
    viewBox='0 0 12 13'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M10.9375 0.5H1.0625C0.780707 0.5 0.510456 0.611942 0.311199 0.811199C0.111942 1.01046 0 1.28071 0 1.5625V4.63812C0.000263506 4.8423 0.0593514 5.04208 0.170193 5.21355C0.281034 5.38502 0.438935 5.52093 0.625 5.605L4.5375 7.38313C3.95819 7.7073 3.50276 8.21448 3.24256 8.82521C2.98235 9.43594 2.93207 10.1157 3.09958 10.7581C3.26709 11.4005 3.64293 11.9691 4.16824 12.375C4.69355 12.7809 5.33865 13.0011 6.0025 13.0011C6.66635 13.0011 7.31145 12.7809 7.83676 12.375C8.36207 11.9691 8.73791 11.4005 8.90542 10.7581C9.07293 10.1157 9.02265 9.43594 8.76244 8.82521C8.50224 8.21448 8.04681 7.7073 7.4675 7.38313L11.375 5.605C11.5611 5.52093 11.719 5.38502 11.8298 5.21355C11.9406 5.04208 11.9997 4.8423 12 4.63812V1.5625C12 1.28071 11.8881 1.01046 11.6888 0.811199C11.4895 0.611942 11.2193 0.5 10.9375 0.5ZM4 1.5H8V6.04187L6 6.95063L4 6.04187V1.5ZM6 12C5.60444 12 5.21776 11.8827 4.88886 11.6629C4.55996 11.4432 4.30362 11.1308 4.15224 10.7654C4.00087 10.3999 3.96126 9.99778 4.03843 9.60982C4.1156 9.22186 4.30608 8.86549 4.58579 8.58579C4.86549 8.30608 5.22186 8.1156 5.60982 8.03843C5.99778 7.96126 6.39991 8.00087 6.76537 8.15224C7.13082 8.30362 7.44318 8.55996 7.66294 8.88886C7.8827 9.21776 8 9.60444 8 10C8 10.5304 7.78929 11.0391 7.41421 11.4142C7.03914 11.7893 6.53043 12 6 12Z'
      fill={color || 'currentColor'}
    />
  </svg>
);
