import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Local Imports
import { AuthLayout, Button, TextInput, showToast } from '../../../components/shared';
import { assets } from '../../../assets/images';
import { isEmpty, isError, validatePassword } from '../../../utils/validations';
import { resetPasswordFunction } from '../../../redux/reducers/authReducers/authReducers';
import { logoutFn } from '../../../utils/utils';


// Reset Password Form Initial Data
const initialResetPasswordData = {
  tempPassword: '',
  newPassword: '',
  confirmPassword: '',
}

// Reset Password Form Errors Initial Data
const initialResetPasswordErrors = {
  tempPassword: '',
  newPassword: '',
  confirmPassword: '',
}

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.user);
  const [resetFormData, setResetFormData] = useState(initialResetPasswordData);
  const [resetFormErrors, setResetFormErrors] = useState(initialResetPasswordErrors);

  const handleFormDataChange = (event) => {
    setResetFormErrors(prev => ({
      ...prev,
      [event.target.name]: '',
    }))
    setResetFormData(prev => ({
      ...prev,
      [event.target.name]: event.target.value
    }))
  }

  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Validate New Password
    if (!validatePassword(resetFormData?.newPassword)) {
      setResetFormErrors(prev => ({
        ...prev,
        newPassword: 'Invalid Password',
      }))
    }

    // Validate Confirm Password
    if (!validatePassword(resetFormData?.confirmPassword)) {
      setResetFormErrors(prev => ({
        ...prev,
        confirmPassword: 'Invalid Password',
      }))
    }

    if (validatePassword(resetFormData?.newPassword) && validatePassword(resetFormData?.confirmPassword)) {
      if (resetFormData?.newPassword !== resetFormData?.confirmPassword) {
        setResetFormErrors(prev => ({
          ...prev,
          confirmPassword: 'Passwords do not match',
        }))
      }
    }

    if (!isError(resetFormErrors) && !isEmpty(resetFormData)) {
      const data = await dispatch(resetPasswordFunction({
        current_password: resetFormData?.tempPassword,
        new_password: resetFormData?.newPassword,
      }));

      if (data?.meta?.requestStatus === 'rejected') {
        showToast(`${data?.payload ? data?.payload[0]?.current_password : 'Something went wrong'}`, 'error', {
          position: 'top-center',
        });
        return;
      }

      if (data?.meta?.requestStatus === 'fulfilled') {
        showToast("Password reset successfully", 'success', {
          position: 'top-center',
        });
        logoutFn();
      }
    }
  }

  return (
    <AuthLayout>
      <div className='w-full h-full flex items-center justify-center'>
        <div className='bg-white py-5 sm:rounded-lg w-full sm:w-[30rem] sm:h-fit h-full pt-12 sm:pt-5 shadow-lg'>

          <div className='sm:max-h-[80vh] overflow-auto px-5'>
            {/* Logo */}
            <div className='font-nunito flex items-baseline justify-center mb-4'>
              <img src={assets.logo} alt="logo" className='size-12' />
              <p className='text-gold mt-3 text-2xl font-bold tracking-wide w-fit'>ealnest.</p>
            </div>

            {/* Login Headings */}
            <div className='font-nunito mb-5'>
              <p className='text-2xl sm:text-3xl font-bold'>Create New Password</p>
              <p className='text-darkgrey text-sm leading-4 mt-3'>Enter the new password below to create your password. It only takes a few seconds to complete.</p>
            </div>

            {/* Login Form */}
            <form className='flex flex-col gap-5 mb-2' onSubmit={handleResetPassword}>
              <TextInput
                label='Temporary Password'
                type='password'
                name='tempPassword'
                error={resetFormErrors?.tempPassword !== ''}
                errorMsg={resetFormErrors?.tempPassword}
                value={resetFormData?.tempPassword}
                onChange={handleFormDataChange}
              />
              <TextInput
                label='New Password'
                type='password'
                name='newPassword'
                error={resetFormErrors?.newPassword !== ''}
                errorMsg={resetFormErrors?.newPassword}
                value={resetFormData?.newPassword}
                onChange={handleFormDataChange}
              />
              <TextInput
                label='Confirm Password'
                type='password'
                name='confirmPassword'
                error={resetFormErrors?.confirmPassword !== ''}
                errorMsg={resetFormErrors?.confirmPassword}
                value={resetFormData?.confirmPassword}
                onChange={handleFormDataChange}
              />

              <ul className='text-xs select-none ml-1.5'>
                <li className='text-darkgrey text-sm vertical-center gap-2'><div className='w-2 h-2 bg-lightsuccess rounded-full' />8 character</li>
                <li className='text-darkgrey text-sm vertical-center gap-2'><div className='w-2 h-2 bg-lightsuccess rounded-full' />1 upper, 1 lower, 1 number and 1 special character</li>
              </ul>

              <Button
                variant={'filled'}
                color={'primary'}
                loading={loading}
                disabled={
                  isEmpty(resetFormData) ||
                  isError(resetFormErrors)
                }
                onClick={isEmpty(resetFormData) || isError(resetFormErrors) ? () => { } : handleResetPassword}
              >
                Reset Password
              </Button>
            </form>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}

export default ResetPassword