import React, { useState } from 'react';

// Local Imports
import { Divider, TextInput } from '../../../../components/shared';
import { CustomModal } from '../../../../components/shared';
import { AddMore } from '../../../../components/shared/AddMore';
import { EditIcon } from '../../../../assets/icons';

export const Experience = () => {
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [experiences, setExperiences] = useState([
    { jobTitle: '', company: '', startDate: '', endDate: '' },
  ]);

  const [projects, setProjects] = useState([
    { projectName: '', location: '', year: '' },
  ]);

  const handleExperienceChange = (index, event) => {
    const { name, value } = event.target;
    setExperiences((prev) =>
      prev.map((exp, i) => (i === index ? { ...exp, [name]: value } : exp))
    );
  };

  const handleProjectChange = (index, event) => {
    const { name, value } = event.target;
    setProjects((prev) =>
      prev.map((proj, i) => (i === index ? { ...proj, [name]: value } : proj))
    );
  };

  const isExperienceComplete = (experience) => {
    return (
      experience.jobTitle &&
      experience.company &&
      experience.startDate &&
      experience.endDate
    );
  };

  const isProjectComplete = (project) => {
    return project.projectName && project.location && project.year;
  };

  const addExperience = () => {
    if (experiences.every(isExperienceComplete)) {
      setExperiences((prev) => [
        ...prev,
        { jobTitle: '', company: '', startDate: '', endDate: '' },
      ]);
    }
  };

  const addProject = () => {
    if (projects.every(isProjectComplete)) {
      setProjects((prev) => [
        ...prev,
        { projectName: '', location: '', year: '' },
      ]);
    }
  };

  const handleSave = () => {
    handleCloseModal();
  };

  const handleCloseModal = () => {
    setOpenExperienceModal(false);
    setExperiences([{ jobTitle: '', company: '', startDate: '', endDate: '' }]);
    setProjects([{ projectName: '', location: '', year: '' }]);
  };

  return (
    <div>
      <div className='font-nunito mt-4 flex items-center justify-between gap-4 bg-white p-2 px-5 rounded-xl'>
        <h3 className='text-xl'>Experience</h3>
        <div
          className='cursor-pointer w-8 h-8 rounded-full hover:bg-grey/30 center transition-all duration-300'
          onClick={() => setOpenExperienceModal(true)}
        >
          <EditIcon />
        </div>
      </div>
      <CustomModal
        title={'Add Experience & Projects'}
        open={openExperienceModal}
        close={handleCloseModal}
        showPrimaryButton={false}
        showSecondaryButton={false}
      >
        <form onSubmit={handleSave} className='space-y-4'>
          <div className='flex flex-col gap-5'>
            <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
              <p className='font-bold text-[14px] text-primary'>Experience</p>
              <p className='font-bold text-[14px] text-darkgray'>*Required</p>
            </div>
            <h2 className='text-lg font-bold text-gray-800'>Experience</h2>
            {experiences.map((experience, index) => (
              <div key={index} className={`space-y-4 ${index > 0 && 'mt-2'}`}>
                <TextInput
                  label='Job Title'
                  name='jobTitle'
                  value={experience.jobTitle}
                  onChange={(e) => handleExperienceChange(index, e)}
                />
                <TextInput
                  label='Company/Employer'
                  name='company'
                  value={experience.company}
                  onChange={(e) => handleExperienceChange(index, e)}
                />
                <div className='flex gap-4'>
                  <TextInput
                    label='Start Date'
                    name='startDate'
                    type='date'
                    value={experience.startDate}
                    onChange={(e) => handleExperienceChange(index, e)}
                  />
                  <TextInput
                    label='End Date'
                    name='endDate'
                    type='date'
                    value={experience.endDate}
                    onChange={(e) => handleExperienceChange(index, e)}
                  />
                </div>
              </div>
            ))}

            <AddMore
              text='Add Experience'
              onClick={addExperience}
            />
          </div>

          <Divider />

          <div className='flex flex-col gap-5'>
            <div className='font-nunito pb-3 flex justify-between items-center border-b-2 border-b-#CCCCCC80 border-dashed'>
              <p className='font-bold text-[14px] text-primary'>Projects</p>
              <p className='font-bold text-[14px] text-darkgray'>*Required</p>
            </div>
            <h2 className='text-lg font-bold text-gray-800'>Projects</h2>
            {projects.map((project, index) => (
              <div key={index} className={`space-y-4 ${index > 0 && 'mt-2'}`}>
                <TextInput
                  label='Project Name'
                  name='projectName'
                  value={project.projectName}
                  onChange={(e) => handleProjectChange(index, e)}
                />
                <TextInput
                  label='Location'
                  name='location'
                  value={project.location}
                  onChange={(e) => handleProjectChange(index, e)}
                />
                <TextInput
                  label='Year'
                  name='year'
                  type='number'
                  value={project.year}
                  onChange={(e) => handleProjectChange(index, e)}
                />
              </div>
            ))}

            <AddMore
              text='Add Project'
              onClick={addProject}
            />
          </div>
        </form>
      </CustomModal>
    </div>
  );
};
