import * as React from 'react';

export const HomeIcon = ({ width, height, ...props }) => (
  <svg
    width={24}
    height={25}
    viewBox='0 0 24 25'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.0068 19.6853C11.5968 19.6853 11.2568 19.3453 11.2568 18.9353V15.9353C11.2568 15.5253 11.5968 15.1853 12.0068 15.1853C12.4168 15.1853 12.7568 15.5253 12.7568 15.9353V18.9353C12.7568 19.3453 12.4168 19.6853 12.0068 19.6853Z'
      fill='currentColor'
    />
    <path
      d='M17.6078 23.4954H6.40777C4.58777 23.4954 2.92777 22.0954 2.62777 20.3054L1.29777 12.3354C1.07777 11.0954 1.68777 9.50537 2.67777 8.71537L9.60777 3.16537C10.9478 2.08537 13.0578 2.09537 14.4078 3.17537L21.3378 8.71537C22.3178 9.50537 22.9178 11.0954 22.7178 12.3354L21.3878 20.2954C21.0878 22.0654 19.3878 23.4954 17.6078 23.4954ZM11.9978 3.86537C11.4678 3.86537 10.9378 4.02537 10.5478 4.33537L3.61777 9.89537C3.05777 10.3454 2.65777 11.3854 2.77777 12.0954L4.10777 20.0554C4.28777 21.1054 5.33777 21.9954 6.40777 21.9954H17.6078C18.6778 21.9954 19.7278 21.1054 19.9078 20.0454L21.2378 12.0854C21.3478 11.3854 20.9478 10.3254 20.3978 9.88537L13.4678 4.34537C13.0678 4.02537 12.5278 3.86537 11.9978 3.86537Z'
      fill='currentColor'
    />
  </svg>
);
