import * as React from "react";

export const ArrowIcon = ({width, height, color, ...props}) => (
  <svg
    width={width || 14}
    height={height || 14}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_641_2291)">
      <path
        d="M10.9902 7.00006C10.9902 7.25087 10.8945 7.50175 10.7034 7.69304L4.68356 13.7127C4.30063 14.0956 3.67978 14.0956 3.297 13.7127C2.91423 13.3299 2.91423 12.7092 3.297 12.3262L8.62357 7.00006L3.29738 1.67368C2.9146 1.2909 2.9146 0.670083 3.29738 0.287341C3.68015 -0.0957724 4.30082 -0.0957724 4.68374 0.287342L10.7036 6.30689C10.8947 6.49828 10.9902 6.74915 10.9902 7.00006Z"
        fill={color || 'currentColor'}
      />
    </g>
  </svg>
);
