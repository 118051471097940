import { cn, Switch } from '@nextui-org/react';
import React from 'react';

export const SwitchButton = ({ label, isSelected, setIsSelected }) => {
  return (
    <Switch
      size='sm'
      classNames={{
        wrapper: 'bg-grey/50',
      }}
      isSelected={isSelected}
      onValueChange={setIsSelected}
    >
      {label}
    </Switch>
  )
};